html, body {
   max-width: 100%;
   overflow-x: hidden;
   font-family: BrandonText !important;
}

label, span, input, div {
   font-family: BrandonText !important;
}

button {
   font-family: BrandonText !important;
   font-weight: 500 !important;
}


.home__title {
   font-family: BrandonText !important;
   font-weight: bolder;
   font-size: 40px;
   color: $pantone123C;
   
}




