.container {
   padding-bottom: 20px;
}

.footerBox {
   background-color: $pantone123C;
   box-sizing: border-box;
   display: flex;
   max-width: 100%;
   text-align: center;

}

.border {
   border: 1px solid rgba(0,0,0,0.5);
}

.powered__text {
   
   font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}