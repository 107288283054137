.container__basic {
   background-color: whitesmoke;
   padding-top:25px;
   padding-left: 75px;
   padding-right: 75px;
   padding-bottom: 25px;
   margin-top: 0px;   
   //border: 2px solid $pantone298C;   
   border-radius: 5px;      
}

.container__blank {
   background-color: white;
}

.container__dataTable, .container__dataTable2, .container__dataTable3{
   background-color: whitesmoke;
   width: 100%;
   border-radius: 5px !important;     
   align-self: center;  
   padding: 0px !important;
   
   //box-shadow: 1px 1px $warmGray;
}

.container__dataTable  {
   border: none !important;             
   //border: 2px solid $pantone298C !important;             
   
}
.container__dataTable2  {
   border: 1px solid $pantone123C !important;    
}

.container__dataTable3  {
   border: none !important;     
   background-color: white;     
}


.container__form {
   background-color: whitesmoke;
   padding-top:25px;
   padding-left: 75px;
   padding-right: 75px;
   padding-bottom: 25px;
   margin-top: 0px;
   border-radius: 5px;  
   //border: 2px solid $pantone298C;   
}

.container__grid {
   border: 1px solid $pantone300C;   
   border-radius: 5px;
   margin-top:6px;
   padding-top:0px !important;
   padding-left: 0px !important;
   padding-bottom: 16px !important;
   margin-left: 16px !important;
   margin-top: 32px !important;      
}

.container__message {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-around;
   align-items: center;

   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.container__message__box {
   display: flex;
   border-radius: 10px;
   align-items: center;      
}

