border {
   border: 1px solid black;
}

.center {
   display: flex;
   margin-left: auto;
   margin-right: auto;
   text-align: center;
   
}

.centerItem {
   position: absolute;
   left: 50%;
   margin: 0;
   transform: translate(-50%, -50%);
}

/*****/
.checkList {
   border: 1px solid orchid; 
   background-color: whitesmoke;
   border-radius: 4px;
   
}

.clickableCell {
   color: blue;
   text-decoration: underline;
   cursor: pointer;
}

.error {
   color: red;
   text-align: left;
   margin-left: 0.5rem;
   font-size: small;
   font-weight: 400;
}

.footerModal {
   display: flex;
   border-top: 2px solid whitesmoke;
   text-align: right;
   font-size: 16px;
   font-weight: bolder;
   align-items: right;
   padding-top: 8px;
   padding-bottom: 8px;
   background-color: whitesmoke;   
}

.form {
   background-color: whitesmoke;
   padding-top:25px;
   padding-left: 75px;
   padding-right: 75px;
   padding-bottom: 25px;
   margin-top: 0px;
}

.grid-item {
   padding-top: 8px !important;
}


.grid-item-noPadding {
   padding-top: 2px !important;
}


.grid-item-rightPadding {
   padding-top: 8px !important;
   padding-right: 16px !important;
}
.headerModal {
   display: flex;
   border-bottom: 2px solid whitesmoke;
   text-align: center;
   font-size: 16px;
   font-weight: bolder;
   align-items: center;
   background-color: whitesmoke;
}

.headerCol {
   background-color:lightgrey;
   color: black;
}

.headerCol2 {
   background-color:$pantone2955C;
   color: white;
}

.helperText {
   padding-top: 1px;
   margin-top: 1px !important;
   font-style: italic;
   font-weight: bolder;
}

.half-width {
   width: 50%;
}

.half-quarter-width {
   width: 75%;
}

.item {
   display: flex;   
   margin-left: 16px !important;
   margin-top: 16px !important;
   width: 80%;
   border: 1px solid black;
}

//Este se usa para mover a la izquierda los iconos de calendarios
//en los componentes DatePicker, que con la variante=Standar se salen
//de la caja.
label+.css-1480iag-MuiInputBase-root-MuiInput-root {
   margin-top: 16px;
   padding-right: 10px !important;
}

.left-align {
   text-align: left !important;
}

.noPadding {
   padding: 0px !important;
}

.primary-font {
   font-family: 'Roboto', sans-serif !important;
   font-size: 12px !important;
}

.quarter-width {
   width: 25%;
}

.right {
   margin-left:auto !important;
}

.right-align {
   text-align: right;
}

.spinner-top-margin {
   margin-top: 128px;
}

.test {

   border: 1px solid;

}

.title {
   text-align: center;
   background-color: $pantone298C;
   color: white;
   //height: 40px;
   justify-content: "center";
   //border: 2px solid $pantone298C;
   border-radius: 5px;
   margin-bottom: 10px;
   padding-top: 15px;
   padding-bottom: 10px;
   //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   font-family: BrandonText;
   font-weight: bold;   
   font-size: 36px;
}

.topMargin {
   margin-top: 32px;
}