@font-face {
    font-family: BrandonText;
    //font-style: normal;
    src:url('../fonts/brandon/BrandonText-Regular.woff2') format('woff2');
}
/*
@font-face {
    font-family: BrandonText;
    font-style: italic;
    src:local('BrandonText-RegularItalic') url("../fonts/brandon/BrandonText-RegularItalic.otf") format('otf');
}

@font-face {
   font-family: BrandonText;
   font-style: normal;
   font-weight: bold;
   src:local(BrandonText) url("../fonts/brandon/BrandonText-Bold.otf") format('otf');
}
*/